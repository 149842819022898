// npm
import React from 'react'
import { Box, BoxProps, styled } from '@mui/material'

export interface MarkdownProps extends BoxProps {
  text: string
}

const Wrapper = styled(Box)(({ theme }) => ({
  ['p:first-of-type']: {
    marginTop: 0,
  },
  h2: {
    marginTop: theme.spacing(4),
    color: 'white',
  },
  ul: {
    paddingLeft: 0,
    listStyle: 'none',
    li: {
      ['&:before']: {
        content: '"• "',
        display: 'inline-block',
        paddingRight: theme.spacing(1),
        color: theme.palette.primary.main,
      },
    },
  },
}))

const Markdown = ({ text, ...props }: MarkdownProps) => {
  return (
    <Wrapper
      dangerouslySetInnerHTML={{
        __html: text,
      }}
      {...props}
    />
  )
}

export default React.memo(Markdown)
