import React, { memo } from 'react'
import { Box } from '@mui/material'
import loadable from '@loadable/component'

import { InternalButtonLinkProps } from '@atoms/buttonLinks/InternalButtonLink'
import { ExternalButtonLinkProps } from '@atoms/buttonLinks/ExternalButtonLink'

const InternalButtonLink = loadable(
  () => import('@atoms/buttonLinks/InternalButtonLink')
)
const ExternalButtonLink = loadable(
  () => import('@atoms/buttonLinks/ExternalButtonLink')
)

export interface CmsLinkButtonProps {
  button: Queries.DatoCmsButton
  internalButtonLinkProps?: Omit<InternalButtonLinkProps, 'children' | 'slug'>
  externalButtonLinkProps?: ExternalButtonLinkProps
}

const Button = ({
  button,
  internalButtonLinkProps,
  externalButtonLinkProps,
}: CmsLinkButtonProps) => {
  if (button.isInternal && button.internalLink && button.internalLink.slug) {
    const DescriptiveText = () => {
      if (button.descriptiveText) {
        return (
          <Box
            component="span"
            sx={{ display: 'block', width: 0, height: 0, overflow: 'hidden' }}
          >
            {button.descriptiveText}
          </Box>
        )
      }
      return <></>
    }

    return (
      <InternalButtonLink
        slug={button.internalLink.slug}
        apiKey={button.internalLink.model.apiKey}
        {...internalButtonLinkProps}
      >
        {button.label}
        <DescriptiveText />
      </InternalButtonLink>
    )
  } else if (button?.externalLinkUrl) {
    return (
      <ExternalButtonLink
        href={button.externalLinkUrl}
        {...externalButtonLinkProps}
      >
        {button.label}
        <DescriptiveText />
      </ExternalButtonLink>
    )
  }
}

export default memo(Button)
