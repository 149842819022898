// npm
import React from 'react'
import {
  Box,
  BoxProps,
  Divider,
  Stack,
  StackProps,
  styled,
} from '@mui/material'

interface Props extends StackProps {
  heading: string
  headingBoxProps: BoxProps
}

const HeadingEnd = ({ heading, headingBoxProps, ...props }: Props) => {
  return (
    <Stack direction="row" alignItems="center" {...props}>
      <Divider
        sx={{
          width: { xs: '30px', lg: '60px' },
          height: '1px',
          backgroundColor: 'text.primary',
        }}
      />
      <Box typography="smHeading" ml={2} my={0} {...headingBoxProps}>
        {heading}
      </Box>
    </Stack>
  )
}

export default React.memo(HeadingEnd)
