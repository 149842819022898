import { Palette, useTheme } from '@mui/material'

const getThemeColorValue = (themeColor: keyof Palette) => {
  const theme = useTheme()
  if (themeColor && themeColor.includes('.')) {
    const attributes = themeColor.split('.')
    if (attributes.length === 2) {
      return theme.palette[attributes[0] as keyof Palette][
        attributes[1] as keyof Palette
      ] as string
    }
  } else {
    return theme.palette[themeColor] as string
  }
}

export default getThemeColorValue
