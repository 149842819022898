import React, { memo } from 'react'
import {
  Button as ButtonBase,
  ButtonProps as ButtonBaseProps,
  styled,
} from '@mui/material'

// styles
import bodyText from '@styles/bodyText'

const StyledButton = styled(ButtonBase)(({ theme }) => ({
  color: 'white',
  borderWidth: '2px !important',
  borderRadius: 100,
  lineHeight: 1,
  whiteSpace: 'nowrap',
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightLight,
  svg: {
    fontSize: '1.5rem !important',
  },
  transition: theme.transitions.create([
    'border-color',
    'background-color',
    'color',
  ]),
  ['&:hover']: {
    color: 'white',
  },
  ['&.MuiButton-sizeLarge']: {
    ...bodyText(),
    paddingTop: '18px',
    paddingBottom: '18px',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  ['.MuiTouchRipple-root']: {
    color: theme.palette.primary.main,
  },
}))

const Button = ({ ...props }: ButtonBaseProps) => {
  return (
    <StyledButton disableElevation variant="outlined" size="large" {...props} />
  )
}

export default memo(Button)
