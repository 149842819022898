// npm
import React, { memo } from 'react'
import { Box, BoxProps } from '@mui/material'

// components
import HeadingEnd from '@atoms/text/HeadingEnd'
import Markdown from '@atoms/text/Markdown'
import SmallHeading from '@atoms/text/SmallHeading'

export interface TextContentProps extends BoxProps {
  smallHeading?: string
  mainHeading: string
  leadText?: string
  text?: string
  markdownWrapperProps?: BoxProps
  excludeMainHeadingDecorator?: boolean
}

const TextContent = ({
  smallHeading,
  mainHeading,
  leadText,
  text,
  markdownWrapperProps,
  excludeMainHeadingDecorator,
  ...props
}: TextContentProps) => {
  return (
    <Box {...props}>
      {smallHeading && (
        <SmallHeading
          heading={smallHeading}
          headingBoxProps={{ component: 'h1' }}
          mb={{ xs: 2, sm: 3 }}
        />
      )}
      <Box
        typography="h2"
        color="white"
        component="h2"
        mt={0}
        mb={!leadText && !text ? 0 : { xs: 2, sm: 3 }}
      >
        {mainHeading}
        {!excludeMainHeadingDecorator && <HeadingEnd />}
      </Box>
      {leadText && (
        <Box
          typography="lgBody"
          dangerouslySetInnerHTML={{
            __html: leadText,
          }}
        />
      )}
      {text && <Markdown text={text} mt={3} {...markdownWrapperProps} />}
    </Box>
  )
}

export default memo(TextContent)
