// npm
import React from 'react'
import { styled } from '@mui/material'

const Wrapper = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
}))

const HeadingEnd = () => {
  return <Wrapper>.</Wrapper>
}

export default React.memo(HeadingEnd)
